<template>
  <div>
    <table-component
      v-on:rowClick="handleRowClick"
      v-if="tabledata"
      :enable-column-filter="true"
      :columns="tableColumns"
      :table-data="tabledata"
      :rowsClickable="true"
    />
  </div>
</template>

<script>
import Utils from '@/utils';
import TableComponent from '../Table/TableComponent.vue';

export default {
  components: { TableComponent },
  name: 'CertificateTable',
  props: ['savedAt', 'tabledata'],
  data() {
    return {
      ajaxCompleted: false,
      tableColumns: [
        {
          title: 'Name',
          key: 'name',
          sort: true,
        },
        {
          title: 'Devices',
          key: 'devices',
          sort: true,
        },
        {
          title: 'Lifetime',
          key: 'lifetime',
          sort: true,
        },
        {
          title: 'Valid to',
          key: 'validTo',
          sort: true,
        },
      ],
    };
  },
  watch: {
    savedAt() {
      this.getcerts();
    },
  },
  methods: {
    handleRowClick(id) {
      this.$emit('rowClick', id);
    },
    duration(ms) {
      return Utils.duration(ms);
    },
  },
  mounted() {
    this.$root.$on('reloadTable', () => {
      this.$emit('reloadData');
    });
  },
};
</script>

<style lang="scss" scoped>
.certificate-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.certificate-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }
}

.bullet {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 100%;

  &.blue {
    background: rgb(88, 195, 220);
  }

  &.green {
    background: #4dbd74;
  }

  &.red {
    background: #f86c6b;
  }
}
</style>
