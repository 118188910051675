<template>
  <div v-if="ajaxCompleted">
    <h1>Deployments for "{{ certificate.name }}"</h1>
    <grid>
      <grid-col size="6">
        <card title="Deployments" v-if="certificate.deployments.length">
          <table class="deployment-table">
            <tr>
              <th>
                <CheckboxProperty @input="doCheckAll" :value="checkAll" />
              </th>
              <th>Device</th>
              <th>Status</th>
              <th>Elapsed</th>
              <th>Checks</th>
              <th></th>
            </tr>
            <tr v-for="(deployment, index) in certificate.deployments" :key="deployment.name">
              <td>
                <CheckboxProperty
                  :value="deployment.selected"
                  @input="
                    (newVal) => {
                      handleCheckClick(newVal, index);
                    }
                  "
                />
              </td>
              <td>
                {{ deployment.name }}
                <router-link :to="{ name: 'devices', params: { device: deployment.device._id } }"
                  ><i class="uil uil-link"></i
                ></router-link>
              </td>
              <td>
                <span v-if="deployment.finished && !deployment.success" :title="deployment.error">
                  <i class="uil uil-browser red" />
                </span>
                <span v-else-if="!deployment.currentlyDeployedSerial">
                  <i class="uil uil-question-circle orange" />
                </span>
                <span v-else-if="deployment.currentlyDeployedSerial !== certificate.serial">
                  <i class="uil uil-times-circle red" />
                </span>
                <span v-else>
                  <i class="uil uil-check-circle green" />
                </span>
              </td>
              <td>
                <span v-if="deployment.started && deployment.finished">
                  {{ elapsed(deployment.started, deployment.finished) }}
                </span>
              </td>
              <td>
                {{ deployment.checks.length }}
              </td>
              <td>
                <a href="#" @click="editDeployment(deployment.device._id)">Edit</a>
              </td>
            </tr>
          </table>
          <div v-if="someCheckboxesSelected">
            <base-button
              v-on:click="deploy"
              type="THEMED"
              :isSmall="true"
              title="Deploy certificate"
            />
          </div>
        </card>
        <card title="Add a deployment">
          <p>Listing devices with the feature "sslCertificate" enabled</p>
          <div>
            <select name="device" class="input-select" v-if="devices" v-model="canAddDeployment">
              <option>{{ defaultAddString }}</option>
              <option
                v-for="device in devices"
                :key="device._id"
                :value="device._id"
                :disabled="deploymentDevices.includes(device._id)"
              >
                {{ device.name }}
              </option>
            </select>
          </div>
          <div>
            <base-button
              v-if="canAddDeployment !== defaultAddString"
              v-on:click="addDeployment"
              type="THEMED"
              title="Add deployment"
            />
            <base-button iconType="CANCEL" v-on:click="onCancel" type="THEMED" title="Cancel" />
          </div>
        </card>
      </grid-col>
      <grid-col size="6">
        <card
          :title="deployment.add ? 'Add deployment' : 'Edit deployment'"
          v-if="deployment && deployment.device"
        >
          <span v-if="deployment.started && deployment.finished">
            Last deployment took {{ elapsed(deployment.started, deployment.finished) }}<br />
            It finished {{ $date(deployment.finished).format('LLL') }}
            <span v-if="deployment.error"
              >and it
              <span class="error">{{ deployment.error ? 'failed' : 'succeeded' }}</span></span
            >
            <span v-else>and it <span class="success">succeeded</span></span>
          </span>

          <Property title="Name">
            <div>
              <input type="text" placeholder="Name" class="input-text" v-model="deployment.name" />
            </div>
          </Property>

          <Property v-if="deployment.uuid" title="UUID">
            {{ deployment.uuid }}
          </Property>

          <Property title="Device">
            {{ deployment.device.name }}
            <span v-if="deployment.device.system">({{ deployment.device.system.os }})</span>
          </Property>

          <Property title="Config">
            <select v-model="deployment.config" name="config" class="input-select" v-if="configs">
              <option :value="null">Select a configuration</option>
              <option v-for="config in configs" :key="config._id" :value="config._id">
                {{ config.name }}
              </option>
            </select>
            <div v-if="deployment.config">
              <br />
              Path : {{ findConfigById(deployment.config).path }}<br />
              Post : {{ findConfigById(deployment.config).script.post }}<br />
              Shared : {{ findConfigById(deployment.config).script.shared.post }}<br />
            </div>
          </Property>

          <Property title="Checks">
            <p>
              {{ deployment.checks.length }} checks
              <i class="uil uil-plus-square" @click="addCheck()"></i>
            </p>
            <grid v-for="(check, index) in deployment.checks" :key="index">
              <grid-col size="3">{{ check.name }}</grid-col>
              <grid-col size="7">{{ check.url }}</grid-col>
              <grid-col size="2">
                <i class="uil uil-pen list" @click="editCheck({ ...check, index })"></i>
                <i class="uil uil-trash-alt red list" @click="deleteCheck(index)"></i>
              </grid-col>
            </grid>
            <grid v-if="editDeployCheck">
              <grid-col size="3">
                <input type="text" class="input-text" v-model="editDeployCheck.name" />
              </grid-col>
              <grid-col size="7">
                <input type="text" class="input-text" v-model="editDeployCheck.url" />
              </grid-col>
              <grid-col size="2">
                <i
                  class="uil uil-save edit green"
                  @click="saveEditCheck(editDeployCheck.index)"
                ></i>
                <i class="uil uil-trash-alt edit red" @click="cancelEditCheck()"></i>
              </grid-col>
            </grid>
          </Property>
          <Property>
            <base-button
              v-if="deployment.config"
              v-on:click="saveDeployment"
              type="THEMED"
              :title="deployment.add ? 'Add' : 'Save'"
            />
            <base-button
              iconType="CANCEL"
              v-on:click="cancelDeployment"
              type="THEMED"
              title="Cancel"
            />

            <base-button
              v-if="!deployment.add"
              type="THEMED_DELETE"
              iconType="TRASH"
              v-on:click="deleteDeployment"
              title="Delete"
              class="right"
            />
          </Property>
        </card>
      </grid-col>
    </grid>
  </div>
</template>

<script>
import Vue from 'vue';
import Utils from '../../utils';
import Property from '../Util/Property.vue';
import BaseComponent from '../Base.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import CheckboxProperty from '../Property/CheckboxProperty.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';

export default Vue.extend({
  name: 'CreateEditcertificate',
  extends: BaseComponent,
  components: {
    Card,
    Grid,
    GridCol,
    BaseButton,
    Property,
    CheckboxProperty,
  },
  props: ['certificateId'],
  watch: {},
  data() {
    return {
      defaultAddString: 'Select a device',
      ajaxCompleted: false,
      certificate: null,
      devices: [],
      configs: [],
      canAddDeployment: 'Select a device',
      deployment: {
        add: false,
        device: null,
        config: null,
        started: null,
        finished: null,
        error: null,
        checks: [],
      }, // the deployment we're editing or adding
      organization: null,
      deploymentDevices: [],
      checkAll: false,
      editDeployCheck: null,
    };
  },
  computed: {
    someCheckboxesSelected() {
      if (!this.certificate || this.certificate.deployments.length <= 0) {
        return false;
      }

      return this.certificate.deployments.some((c) => c.selected);
    },
  },
  methods: {
    addCheck() {
      this.editDeployCheck = {
        index: this.deployment.checks.length,
        name: '',
        url: '',
      };
    },
    editCheck(check) {
      this.editDeployCheck = check;
    },
    cancelEditCheck() {
      this.editDeployCheck = null;
    },
    saveEditCheck(index) {
      if (typeof this.deployment.checks[index] === 'undefined') {
        this.deployment.checks.push({}); // empty object
      }
      this.deployment.checks[index].name = this.editDeployCheck.name;
      this.deployment.checks[index].url = this.editDeployCheck.url;
      this.cancelEditCheck();
    },
    deleteCheck(index) {
      this.$delete(this.deployment.checks, index);
    },
    onCancel() {
      this.$router.push(`/certificate/${this.certificateId}/edit`);
    },
    elapsed(st, fi) {
      return Utils.durationShort(this.$date(fi).subtract(this.$date(st)), {
        units: ['m', 's'],
        round: true,
      });
    },
    doCheckAll() {
      if (this.certificate) {
        this.checkAll = !this.checkAll;
        for (let i = 0; i < this.certificate.deployments.length; i += 1) {
          this.certificate.deployments[i].selected = this.checkAll;
        }
      }
    },
    async getcertificate(id) {
      const response = await Utils.fetch(`/api/v1/certificates/${id}`, {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.ajaxCompleted = true;
        const { certificate } = response;
        certificate.deployments.map((d) => {
          // eslint-disable-next-line no-param-reassign
          d.selected = false;
          if (typeof d.checks === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            d.checks = [];
          }
          return d;
        });

        this.certificate = certificate;

        this.getdeployconfigs();
        this.getdevices().then(() => {
          this.deploymentDevices = this.findDeploymentDeviceIds();
        });
      }
    },
    async getdevices() {
      const response = await this.$utils.http().get('/api/v1/certificates/eligible-devices');
      this.devices = response.data;
    },
    async getdeployconfigs() {
      const response = await Utils.fetch(
        '/api/v1/certificates/deploy/config',
        {},
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.configs = response.configs;
      }
    },
    async onChangeConfig(event) {
      this.deployment.config = event.target.value;
    },
    resetDeployment() {
      this.deployment.add = false;
      this.deployment.device = null;
      this.deployment.config = null;
      this.deployment.name = null;
      this.deployment.started = null;
      this.deployment.error = null;
      this.deployment.checks = [];
    },
    async cancelDeployment() {
      // reload the deployment in case someone removed a check
      await this.getcertificate(this.certificateId);

      this.resetDeployment();
    },
    async addDeployment() {
      this.resetDeployment();
      this.deployment.add = true;
      this.deployment.device = this.findDeviceById(this.canAddDeployment);
      this.deployment.name = this.deployment.device.name;
      this.deployment.config = null;
    },
    async editDeployment(device) {
      const deploy = this.findDeploymentByDeviceId(device);
      this.deployment.add = false;
      this.deployment.device = this.findDeviceById(device);
      this.deployment.name = deploy.name ?? this.deployment.device.name;
      this.deployment.uuid = deploy.uuid;
      this.deployment.config = deploy.config;
      this.deployment.started = deploy.started;
      this.deployment.finished = deploy.finished;
      this.deployment.error = deploy.error;
      this.deployment.checks = deploy.checks ?? [];
    },
    async saveDeployment() {
      if (this.editDeployCheck) {
        this.saveEditCheck(this.editDeployCheck.index);
      }
      const response = await Utils.post(
        `/api/v1/certificates/${this.certificate._id}/deploy`,
        {
          add: this.deployment.add,
          name: this.deployment.name,
          device: this.deployment.device._id,
          config: this.deployment.config,
          checks: this.deployment.checks,
        },
        this,
      );
      if (response.success) {
        await this.getcertificate(this.certificateId);
        this.resetDeployment();
        this.canAddDeployment = this.defaultAddString;
        this.$emit('saved');
        this.$noty.success('Saved deployment');
      }
    },
    async deleteDeployment() {
      const response = await Utils.delete(
        `/api/v1/certificates/${this.certificate._id}/deploy`,
        {
          device: this.deployment.device._id,
        },
        this,
      );
      if (response.success) {
        await this.getcertificate(this.certificateId);
        this.resetDeployment();
        this.canAddDeployment = this.defaultAddString;
        this.$emit('saved');
        this.$noty.success('Deleted deployment');
      }
    },
    findDeviceById(id) {
      return (this.devices.filter((device) => device._id === id) || []).shift();
    },
    findDeploymentDeviceIds() {
      return this.certificate.deployments.map((deployment) => deployment.device._id) || [];
    },
    findDeploymentByDeviceId(id) {
      return (
        this.certificate.deployments.filter((deploy) => deploy.device._id === id) || []
      ).shift();
    },
    findConfigById(id) {
      return (this.configs.filter((config) => config._id === id) || []).shift();
    },
    handleCheckClick(value, index) {
      if (!this.certificate) {
        return;
      }

      this.certificate.deployments[index].selected = value;
    },
    async deploy() {
      const devices = this.certificate.deployments
        .filter((c) => c.selected)
        .map((c) => c.device._id);
      try {
        await this.$utils
          .http()
          .post(`/api/v1/certificates/${this.certificate._id}/execute-deploy`, {
            devices,
          });
        this.$noty.success('Successfully deployed certificate!');
        setTimeout(() => {
          this.getcertificate(this.certificateId);
        }, 2000);
      } catch (e) {
        this.$noty.warning(`could not deploy certificate: ${e}`);
      }
    },
  },
  async mounted() {
    this.organization = await Utils.getOrganization();
    this.getcertificate(this.certificateId);
  },
});
</script>

<style lang="scss" scoped>
.deployment-table {
  margin-top: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);

  tr th {
    text-align: left;
  }

  tr td,
  tr th {
    padding: 4px 5px;
  }

  tr:not(:last-child) td,
  tr:not(:last-child) th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
/* icons next to inputs need spacing top */
.uil.edit,
.uil.list {
  font-size: 110%;
}
.uil.edit {
  top: 6px;
  position: relative;
}
</style>
